@import
url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,400italic,300italic,700,700italic&subset=latin,latin-ext);

@font-face {
    font-family: Bebas Neue;
    src: local("fonts/BebasNeue.otf"),
    local("fonts/BebasNeue.otf"),
    url(/fonts/BebasNeue.otf);
}
body{
    margin:0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #fff;
    background: #000 url(/img/bg.jpg);
    font-weight: normal;
}
.clr{
    clear: both;
}
a{
    text-decoration: none;
    color: #a9a9a9;
}
a:hover{
    color: #fff;
}
li{
    list-style: none;
}
h2{
    margin: 0;
    padding: 0;
}
.container{
    width: 960px;
    margin:auto;
}
#top{
    width: 100%;
    height: 50px;
    background-color: #000;
}
.box-top{
    border-left: 1px solid #131313;
    border-right: 1px solid #131313;
    height: 50px;
    float:left;
    padding: 15px 25px;
    color: #a9a9a9;
}
.box-right{
    float:right;
    border-left: 1px solid #131313;
    border-right: 1px solid #131313;
    height: 50px;
    padding: 15px 25px;
}
#slider{
    background: transparent url('/img/slider-bg.png') center center no-repeat;
    background-size: cover;
    min-width: 100%;
    height: 535px;
}
.photo{
    background-image: url(/img/1.jpg);
    width: 960px;
    height: 352px;
    position: absolute;
}
.menu-bg{
    background-image: url(/img/transparent.png);
    width: 960px;
    height: 95px;
    margin-top:40px;
}
.logo{
    background-image: url(/img/logo.png);
    width: 264px;
    height: 50px;
    float: left;
    margin: 27px 0 0 30px;
}
ul.menu{
    float:right;
    margin: 0;
    padding: 0;
}
li.kategorie{
    background: url(/img/kat.png) no-repeat 33px 18px transparent;
    padding-top: 60px;
    float:left;
    width: 100px;
    text-align: center;
}
li.top{
    background: url(/img/top.png) no-repeat 31px 18px transparent;
    padding-top: 60px;
    float:left;
    width: 100px;
    text-align: center;
}
li.nowegry{
    background: url(/img/nowe.png) no-repeat 30px 18px transparent;
    padding-top: 60px;
    float:left;
    width: 100px;
    text-align: center;
}
li.dlaciebie{
    background: url(/img/dlaciebie.png) no-repeat 33px 18px transparent;
    padding-top: 60px;
    float:left;
    width: 100px;
    text-align: center;
}
li.login{
    background: url(/img/login.png) no-repeat 37px 18px transparent;
    padding-top: 60px;
    float:left;
    width: 100px;
    text-align: center;
}
ul.menu li{
    padding-bottom: 15px;
    color: #fff;
}
ul.menu li:hover{
    background-color: #000;
}
.ava{
    background-image: url(/img/ava.png);
    width: 353px;
    height: 191px;
    margin: 28px 0 0 -40px;
}
.ava article{
    color: #fff;
    font-size: 14px;
    padding: 23px 0 0 162px;
}
#content{
    margin-top: -183px;
    position: relative;
    z-index: 2;
}
.content-bg{
    background-color: #fff;
    padding: 20px;
}
.left{
    width: 670px;
    float:left;
}
.right{
    width: 230px;
    float: left;
    margin-left:20px;
}
#content h2{
    font-family: Bebas Neue;
    font-size: 30px;
    color: #171717;
    float:left;
}
.line{
    background-image: url(/img/line.png);
    width: 63%;
    height: 21px;
    float: right;
    margin-top: 6px;
}
.box{
    text-align: center;
    border:1px solid #e6e6e6;
    width: 210px;
    height: 170px;
    float: left;
    box-shadow: 3px 3px 0 #f2f2f2;
    margin-top:15px;
    margin-left: 17px;
}
.box:nth-child(0){
    margin-left: 0px;
}
.box header{
    font-size: 14px;
    color: #171717;
    text-align: center;
}
.btn{
    background-color: #d2382c;
    width: 80px;
    height: 32px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 31px;
    text-align: left;
    padding-left: 20px;
    margin: 10px auto auto;
}
.btn:hover{
    background-color: #e25044;
}
.arrow{
    background-image: url(/img/arrow.png);
    width: 10px;
    height: 7px;
    float: right;
    margin: 13px 18px 0 0;
}
.box img{
    margin-top:10px;
}
.right header{
    font-size: 14px;
    color: #171717;
    margin-top:10px;
}
.right input{
    border:1px solid #e6e6e6;
    font-size: 12px;
    color: #8a8a8a;
    font-style: italic;
    padding: 15px;
    margin-top: 10px;
    float:left;
    width: 152px;
}
.loupe{
    background: #D2382C url(/img/loupe.png) no-repeat 14px 14px;
    float: left;
    width: 46px;
    height: 46px;
    margin-top: 10px;
}
.loupe:hover{
    background-color: #e25044;
}
.topgry{
    margin-top: 20px;
}
.game{
    background-image: url(/img/44.jpg);
    width: 230px;
    height: 279px;
}
.game header{
    font-family: Bebas Neue;
    font-size: 30px;
    text-align: center;
    color: #fff;
    padding-top: 190px;
}
.button{
    color: #131313;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    height: 32px;
    background-color: #e6e6e6;
    margin: auto;
    line-height: 31px;
}
.button:hover{
    background-color: #f5f5f5;
}
.banners img{
    float:left;
    margin-top:30px;
}
.footer-bg{
    background-color: #000;
    height: 20px;
    padding: 25px;
    margin-bottom: 40px;
}
#footer article{
    font-size: 14px;
    color: #9a9a9a;
    float:left;
}
ul.bot-menu{
    float:right;
    margin:0;
    padding: 0;
    color: #9a9a9a;
}
ul.bot-menu li{
    float:left;
    margin-left: 15px;
}
.left input{
    border: 1px solid #e6e6e6;
    font-size: 12px;
    color: #8a8a8a;
    font-style: italic;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    float: left;
    width: 90%;
}
input[type="checkbox"] {
    width: 2%;
}
.btn-normal{
    background-color: #d2382c;
    height: 40px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 39px;
    text-align: center;
    margin-top: 10px;
    float: left;
    padding: 0 15px;
    border: none;
}
.btn-normal:hover{
    background-color: #e25044;
}
.lft{
    float:left;
    width: 320px;
}
.rgh{
    float:right;
    width: 320px;
}
.text{
    margin-top:15px;
    font-size: 14px;
    color: #171717;
}
.text a{
    font-size: 14px;
    color: #171717;
    font-weight: bold;
}
.lft img, .rgh img{
    margin-top:10px;
}
.dladzieci h3{
    background: url('/img/categories.png') no-repeat 0 0;
    padding: 6px 0 0 40px;
    height: 26px;
}
.sport h3{
    background: url('/img/categories.png') no-repeat 0 -33px;
    padding: 6px 0 0 40px;
    height: 26px;
}
.akcja h3{
    background: url('/img/categories.png') no-repeat 0 -68px;
    padding: 6px 0 0 40px;
    height: 26px;
}
.wyscigi h3{
    background: url('/img/categories.png') no-repeat 0 -99px;
    padding: 6px 0 0 40px;
    height: 26px;
}
.zrecznosciowe h3{
    background: url('/img/categories.png') no-repeat 0 -128px;
    padding: 6px 0 0 40px;
    height: 26px;
}
.logiczne h3{
    background: url('/img/categories.png') no-repeat 0 -159px;
    padding: 6px 0 0 40px;
    height: 26px;
}
.lft h3:hover, .rgh h3:hover{
    color: #d2382c;
}
.box-cat{
    text-align: center;
    border: 1px solid #e6e6e6;
    width: 149px;
    height: 158px;
    float: left;
    box-shadow: 3px 3px 0 #f2f2f2;
    margin-left: 17px;
    font-size: 12px;
}
.box-cat header{
    font-size: 10px;
    color: #171717;
    text-align: center;
}
.wiecej{
    margin-top:15px;
    font-size: 11px;
    background-color: #F1F1F1;
    padding: 5px 0;
    text-align: center;
}
.wiecej:hover{
    background-color: #E8E8E8;
}
ul.rozwin {
    display: none;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
    background-color: #000;
    margin-top: 15px;
    padding: 0px;
}
li.kategorie:hover > ul.rozwin {
    display: block;
    position: absolute;
}
ul.rozwin li{
    padding: 8px 7px 7px 5px;
    border: 1px solid #222;
}
ul.rozwin li:hover{
    background-color: #1D1D1D;
}
table.tabelka{
    color: #171717;
    width: 100%;
    text-align: left;
}
table.tabelka th{
    background-color: #F0F0F0;
    padding: 10px;
}
table.tabelka tr{
    background-color: #F6F6F6;
}
table.tabelka tr:nth-child(odd){
    background-color: #fff;
}
table.tabelka tr:hover{
    background-color: #F3F3F3;
}
table.tabelka td{
    padding: 10px;
}
table.tabelka img{
    float: left;
    margin-right:10px;
}
table.tabelka p{
    color: #7D7D7D;
    font-size: 12px;
}
td.big{
    font-size: 18px;
}
td.pozycja{
    font-size: 30px;
    text-align: center;
}
table.tabelka a{
    color: #171717;
}
table.tabelka a:hover{
    text-decoration: underline;
}
.box-nowe{
    text-align: center;
    border:1px solid #e6e6e6;
    padding: 10px;
    float: left;
    box-shadow: 3px 3px 0 #f2f2f2;
    padding-bottom: 7px;
    margin-right: 15px;
}
dl.nowe{
    color: #171717;
}
dl.nowe header{
    font-size: 22px;
    margin-top: 15px;
}
dl.nowe article{
    font-size: 13px;
    margin-top: 5px;
}
dl.nowe span{
    font-size: 12px;
    color: #A8A8A8;
}
dl.nowe a{
    color: #d2382c;
}
dl.nowe a:hover{
    color: #EE695E;
}
.linex{
    height: 1px;
    width: 100%;
    background-color: #EEEEEE;
    margin-top: 16px;
}
.dla-cb{
    color: #171717;
}
.dla-cb header{
    font-size: 30px;
    font-weight: bold;
}
.dla-cb span{
    margin-top: 10px;
    font-size: 13px;
}
.dla-cb img{
    margin-top:10px;
}
.sackboy{
    background-image: url(/img/sackboy.png);
    width: 170px;
    height: 273px;
    float: right;
    margin: -300px -170px 0 0;
    position: relative;
    z-index: 1;
}

.alert-danger {
    color: #C9302C;
}

input.error {
    border: 1px solid #C9302C;
}

.pagination {
    height: 36px;
    margin: 18px 0;
}
.pagination ul {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
    display: inline;
}
.pagination a,
.pagination span,
.pagination .active span{
    color: #d2382c;
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #d2382c;
    border-left-width: 0;
}
.pagination a:hover,
.pagination .active span {
    color: #fff;
    background-color: #d2382c;
}
.pagination .active a {
    color: #d2382c;
    cursor: default;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
    color: #d2382c;
    background-color: transparent;
    cursor: default;
}
.pagination li:first-child a,
.pagination li:first-child span {
    border-left-width: 1px;
}
.pagination li:last-child a {
}
.pagination-centered {
    text-align: center;
}
.pagination-right {
    text-align: right;
}

embed {
    width: 670px!important;
}